import React from "react";
import './App.css';
import TopNavbar from './components/common/Header';
import Footer from './components/common/Footer';

function App() {
  return (
    <React.Fragment>
      <TopNavbar/>
      <Footer />
    </React.Fragment>
  );
}

export default App;
