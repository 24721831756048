import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Main.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container } from 'react-bootstrap';

function ServiceList() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active href="/services">Services</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="service-section" id="id3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2 ">
            <div className="sec-title sec-title__lg-title md-text-center">
              <h2 className="sec-title__title">Our services</h2><span className="sec-title__divider"></span>
            </div>
            
          </div>
        </div>
        <div className="row row-eq-height">
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/design.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/design">Design</a></h2>
              <div className="services__desc">A design is a plan or specification for the construction of an object or system or for the implementation of an activity or process, or the result of that plan or specification in the form of a prototype, product or process.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/design">read more
              </a>
              
            </div>
            
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/civil.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/civil-construction">Civil Construction Works</a></h2>
              <div className="services__desc">Civil construction falls in the category of civil engineering which is all about designing, constructing and maintaining the physical and naturally built environment.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/civil-construction">read more
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/rainwater.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/rainwater-harvesting">Rainwater Harvesting</a></h2>
              <div className="services__desc">Rain water harvesting is a technique of collection and storage of rain water in surface (storage tanks) or sub surface aquifer before it is lost as surface runoff.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/rainwater-harvesting">read more
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/highway.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/highway-and-industry">Highway & Industrial Roads</a></h2>
              <div className="services__desc">The highways and roads market refers to the construction and maintenance of transportation infrastructure including highways, railroads, bridges and other road.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/highway-and-industry">read more
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/fabrication.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/fabrication">Fabrication</a></h2>
              <div className="services__desc">Metal fabrication is the creation of metal structures by cutting, bending and assembling processes. ... As with other manufacturing processes, both human labor and automation are commonly used. A fabricated product may be called a fabrication, and shops specializing in this type of work are called fab shops.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/fabrication">read more
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/road.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/roads">Roads</a></h2>
              <div className="services__desc">A road is a thoroughfare, route, or way on land between two places that has been paved or otherwise improved to allow travel by foot or by some form of conveyance (including a motor vehicle, cart, bicycle, or horse).</div>
              <a className="btn btn btn-primary btn-custom" href="/service/roads">read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
export default function Services(){
  return (
  <> 
    <ServiceList />
  </>
  );
}