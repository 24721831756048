import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Main.css';
import {Container} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function ProjectList() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Projects</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="project_section">
      <Container>
        <div className="row">
          <div className="col-lg-12 col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2 ">
            <div className="sec-title sec-title__lg-title md-text-center">
              <h2 className="sec-title__title">Our Projects</h2><span className="sec-title__divider"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/lights.jpg">
                <img src="/images/simara.jpg" alt="Road" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Simara Road Construction</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/nature.jpg">
                <img src="/images/civil.jpg" alt="Civil" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Siraha Projects</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/fjords.jpg">
                <img src="/images/hydro.jpg" alt="Hydro" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Construction of Hydroelectric Project</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/lights.jpg">
                <img src="/images/bridge.jpg" alt="Bridge" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Construction of 80m bridge</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/nature.jpg">
                <img src="/images/road.jpg" alt="Road" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Construction of Road Siraha</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/fjords.jpg">
                <img src="/images/rainwater.jpg" alt="Sypply" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Small towns water supply & sanitation sector project</h2>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
    </>
  );
}
export default function Projects(){
  return (
  <> 
    <ProjectList />
  </>
  );
}