import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Main.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container } from 'react-bootstrap';

function ServiceDesign() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
          <Breadcrumb.Item active>Design</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 ">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title"><span>Design</span></h2><span className="sec-title__divider"></span>
              </div>
              <p>A design is a plan or specification for the construction of an object or system or for the implementation of an activity or process, or the result of that plan or specification in the form of a prototype, product or process.</p>
              <p>The verb to design expresses the process of developing a design.</p><br />
            </div>
          </div>
          <div className="col-lg-7 ">
            <div className="service-detail">
              <img src="/images/design.jpg" alt="Rabiya Construction Design"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
function ServiceCivilConstruction() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
          <Breadcrumb.Item active>Civil Construction Works</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 ">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title"><span>Civil Construction Works</span></h2><span className="sec-title__divider"></span>
              </div>
              <p>Civil construction falls in the category of civil engineering which is all about designing, constructing and maintaining the physical and naturally built environment.</p>
              <p>Civil construction is the art of building bridges, dams, roads, airports, canals, and buildings.</p>
            </div>
          </div>
          <div className="col-lg-7 ">
            <div className="service-detail">
              <img src="/images/civil.jpg" alt="Civil Construction"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

function ServiceRainwaterHarvesting() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
          <Breadcrumb.Item active>Rain Water Harvesting</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 ">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title"><span>Rainwater Harvesting</span></h2><span className="sec-title__divider"></span>
              </div>
              <p>Rain water harvesting is a technique of collection and storage of rain water in surface (storage tanks) or sub surface aquifer before it is lost as surface runoff.</p>
            </div>
          </div>
          <div className="col-lg-7 ">
            <div className="service-detail">
              <img src="/images/rainwater.jpg" alt="Rainwater Harvesting"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

function ServiceHighwayIndustry() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
          <Breadcrumb.Item active>Highway & Industrial Roads</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 ">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title"><span>Highway & Industrial Roads</span></h2><span className="sec-title__divider"></span>
              </div>
              <p>The highways and roads market refers to the construction and maintenance of transportation infrastructure including highways, railroads, bridges and other road.</p>
            </div>
          </div>
          <div className="col-lg-7 ">
            <div className="service-detail">
              <img src="/images/highway.jpg" alt="Highway & Industrial Roads"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}


function ServiceFebrication() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
          <Breadcrumb.Item active>Fabrication</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 ">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title"><span>Fabrication</span></h2><span className="sec-title__divider"></span>
              </div>
              <p>Metal fabrication is the creation of metal structures by cutting, bending and assembling processes. ... As with other manufacturing processes, both human labor and automation are commonly used.</p>
              <p> A fabricated product may be called a fabrication, and shops specializing in this type of work are called fab shops.</p>
            </div>
          </div>
          <div className="col-lg-7 ">
            <div className="service-detail">
              <img src="/images/fabrication.jpg" alt="Fabrication"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

function Roads() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
          <Breadcrumb.Item active>Roads</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 ">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title"><span>Roads</span></h2><span className="sec-title__divider"></span>
              </div>
              <p>A road is a thoroughfare, route, or way on land between two places that has been paved or otherwise improved to allow travel by foot or by some form of conveyance (including a motor vehicle, cart, bicycle, or horse).</p>
            </div>
          </div>
          <div className="col-lg-7 ">
            <div className="service-detail">
              <img src="/images/road.jpg" alt="Roads"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
export { ServiceDesign, ServiceCivilConstruction, ServiceRainwaterHarvesting, ServiceHighwayIndustry, ServiceFebrication, Roads }