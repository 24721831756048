import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Main.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container} from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { BrowserRouter as Router, Routes, Route, NavLink} from "react-router-dom";
import HomePage from '../../components/pages/Home.jsx';
import AboutDetail from '../../components/pages/AboutUs.jsx';
import ServiceList from '../../components/pages/ServiceList.jsx';
import ProjectList from '../../components/pages/ProjectList.jsx';
import ContactUs from '../../components/pages/ContactUs.jsx';
import { ServiceDesign, ServiceCivilConstruction, ServiceRainwaterHarvesting, ServiceHighwayIndustry, ServiceFebrication, Roads } from '../../components/pages/ServiceDetail.jsx';
import { AiOutlineMail, AiOutlinePhone, AiOutlineHeatMap, AiOutlineFacebook, AiFillTwitterSquare, AiOutlineWhatsApp, AiOutlineInstagram  } from "react-icons/ai";

function TopNavbar() {
  const [expand, updateExpanded] = useState(false);
    return (
      <Router>
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col col-sm-9">
                <div className="contact-info">
                  <ul>
                    <li>
                      <a href="mailto:mseraj782@gmail.com"><AiOutlineMail /> mseraj782@gmail.com </a>
                    </li>
                    <li>
                        <a href="tel:9862180882"><AiOutlinePhone /> 9862180882</a>
                    </li>
                    <li><AiOutlineHeatMap /> Sunsari, Nepal</li>
                  </ul>
                </div>
              </div>
              <div className="col col-sm-3">
                <div className="social">
                  <div className="social-links">
                    <ul>
                      <li><a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><AiOutlineFacebook title="Facebook" /></a></li>
                      <li><a href="https://www.twitter.com/" target="_blank" rel="noreferrer"><AiFillTwitterSquare title="Twitter" /></a></li>
                      <li><a href="https://www.instagram.com/" target="_blank" rel="noreferrer"><AiOutlineInstagram title="Instagram" /></a></li>
                      <li><a href="https://www.whatsapp.com/" target="_blank" rel="noreferrer"><AiOutlineWhatsApp title="WhatsApp" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navbar expanded={expand} sticky="top" expand="lg" className="top_nav">
          <Container>
            <Navbar.Brand as={NavLink} to="/" onClick={()=> updateExpanded(false)}>
              <Image src="/images/logo.jpg" alt="Logo" className="img-responsive"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"
              onClick={() => {
                updateExpanded(expand ? false : "expanded");
              }}/>
            <Navbar.Collapse>
              <Nav className="mx-auto my-0 my-lg-0">
                <Nav.Link as={NavLink} to={"/home"} onClick={()=> updateExpanded(false)}>Home</Nav.Link>
                <Nav.Link as={NavLink} to={"/about-us"} onClick={()=> updateExpanded(false)}>About Us</Nav.Link>
                <Nav.Link as={NavLink} to={"/services"} onClick={()=> updateExpanded(false)}>Services</Nav.Link>
                <Nav.Link as={NavLink} to={"/projects"} onClick={()=> updateExpanded(false)}>Projects</Nav.Link>
                <Nav.Link as={NavLink} to={"/contact-us"} onClick={()=> updateExpanded(false)}>Contact Us</Nav.Link>
              </Nav>  
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div>
          <Routes>
            <Route path='/' element={<HomePage/>} />
            <Route path='/home' element={<HomePage/>} />
            <Route path='/about-us' element={<AboutDetail/>} />
            <Route path='/services' element={<ServiceList/>} />
            <Route path='/service/design' element={<ServiceDesign/>} />
            <Route path='/service/civil-construction' element={<ServiceCivilConstruction/>} />
            <Route path='/service/rainwater-harvesting' element={<ServiceRainwaterHarvesting/>} />
            <Route path='/service/highway-and-industry' element={<ServiceHighwayIndustry/>} />
            <Route path='/service/fabrication' element={<ServiceFebrication/>} />
            <Route path='/service/roads' element={<Roads/>} />
            <Route path='/projects' element={<ProjectList/>} />
            <Route path='/contact-us' element={<ContactUs/>} />
          </Routes>
        </div>
      </Router>
    );
}

export default TopNavbar;