import React from "react";
import '../Styles/Main.css';
import { AiOutlineMail, AiOutlinePhone, AiOutlineHeatMap, AiOutlineFacebook, AiFillTwitterSquare, AiOutlineWhatsApp, AiOutlineInstagram, AiFillCaretRight   } from "react-icons/ai";
import { AiOutlineHome, AiOutlineProject, AiOutlineUsergroupAdd, AiFillTrophy } from "react-icons/ai";

function FooterAchiment() {
  return (
        <div className="home_achieve_quote">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6 achieve">
                <AiOutlineProject />
                <span className="counter">200</span>
                <h6>Projects Completed</h6>
              </div>
              <div className="col-lg-3 col-sm-6 achieve">
                <AiOutlineHome />
                <span className="counter">132</span>
                <h6>Houses Renovation</h6>
              </div>
              <div className="col-md-3 col-sm-6 achieve">
                <AiOutlineUsergroupAdd />
                <span className="counter">50</span>
                <h6>Workers Employed</h6>
              </div>
              <div className="col-md-3 col-sm-6 achieve">
                <AiFillTrophy />
                <span className="counter">20</span>
                <h6>Awards Won</h6>
              </div>
            </div>
            <hr />
          </div>
        </div>
        );
}

function FooterMenu(){
	let date = new Date();
    let year = date.getFullYear();
	return(
		<section className="footer">
			<div className="footer_body">
	          <div className="container">
	            <div className="row fcollect">
	              	<div className="col-lg-3 footer_about">
        				<h2>About Our Company</h2>
        				<p>Our company was established in Sunsari Nepal in the year of 2006. 
        				We "Rabiya Construction" are engaged in providing various kind of civil 
        				construction services that include Rainwater , </p>
				        <ul className="socail_icon"> 
					        <li>
						        <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
						        	<AiOutlineFacebook title="Facebook" />
						        </a>
					        </li> 
					        <li>
						        <a href="https://twitter.com" target="_blank" rel="noreferrer">
						        	<AiFillTwitterSquare title="Twitter" />
						        </a>
					        </li> 
					        <li>
						        <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
						        	<AiOutlineInstagram title="Instagram" />
						        </a>
					        </li> 
					        <li>
					        	<a href="https://whatsapp.com/" target="_blank" rel="noreferrer">
					        		<AiOutlineWhatsApp title="WhatsApp" />
					        	</a>
					        </li>
				        </ul>
	              	</div>
		            <div className="col-lg-3 col-sm-6 footer_about quick">
				        <h2>Quick links</h2>
				        <ul className="quick_link">
					        <li>
						        <a href="/service/design" id="id_designing">
						        	<AiFillCaretRight /> Design
						        </a>
					        </li>
					        <li>
						        <a href="/service/civil-construction" id="id_laying_services">
						        	<AiFillCaretRight /> Civil Construction Works
						        </a>
					        </li>
					        <li>
						        <a href="/service/rainwater-harvesting" id="id_rain_water_harvesting">
						        	<AiFillCaretRight /> Rainwater Harvesting
						        </a>
					        </li>
					        <li>
						        <a href="/service/highway-and-industry" id="id_roads">
						        	<AiFillCaretRight /> Highway & Industrial Roads
						        </a>
					        </li>
					        <li>
						        <a href="/service/fabrication" id="id_rain_water_harvesting">
						        	<AiFillCaretRight /> Fabrication
						        </a>
					        </li>
					        <li>
						        <a href="/service/roads" id="id_roads">
						        	<AiFillCaretRight /> Roads
						        </a>
					        </li>
				        </ul>
        			</div>
		            <div className="col-lg-3 col-sm-6 footer_about">
				        <h2>Facebook Feed</h2>
				      <div className="fb-page fb_iframe_widget" data-href="https://www.facebook.com/Rabiya-Construction-Pvtltd-860532690800832" data-tabs="timeline" data-width="250" data-height="200" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" fb-xfbml-state="rendered" fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=263&amp;height=200&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FRabiya-Construction-Pvtltd-860532690800832&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=true&amp;tabs=timeline&amp;width=250"><span style={{ verticalAlign: "bottom", width: "250px", height: "200px" }}><iframe name="f13d6a57137a734" width="250px" height="200px" data-testid="fb:page Facebook Social Plugin" title="fb:page Facebook Social Plugin" frameBorder="0" allowtransparency="true" allowFullScreen={true} scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v9.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1defcadc5a7ac4%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Flocalhost%252Ff1595756ce18e94%26relation%3Dparent.parent&amp;container_width=263&amp;height=170&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FRabiya-Construction-Pvtltd-860532690800832&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=true&amp;tabs=timeline&amp;width=250" style={{ border: "none", visibility: "visible", width: "250px", height: "200px" }} className=""></iframe></span></div>
				    </div>
		            <div className="col-lg-3 col-sm-6 footer_about">
				        <h2>Contact Us</h2>
				          <p>Have questions, comments or just want to say hello:</p>
				          <ul className="my_address">
				            <li>
					            <a href="mailto:mseraj782@gmail.com">
					            	<AiOutlineMail /> mseraj782@gmail.com
					            </a>
				            </li>
				            <li>
					            <a href="tel:9862180882">
					            	<AiOutlinePhone /> 9862180882
					            </a>
				            </li>
				            <li>
					            	<AiOutlineHeatMap /> Sunsari, Nepal
				            </li>
				          </ul>
				    </div>
	            </div>
	          </div>
	        </div>
	        <div className="copyright_area">
			   <div className="copyright align-items-center">
			        <small>© Copyright { year } Rabiya Construction Pvt. Ltd.&nbsp;All Rights Reserved. Developed by<a href="http://manasluinfotech.com/" target="_blank" rel="noreferrer"> Manaslu Infotech Nepal. </a></small>
			    </div>
			</div>
        </section>
        );
}

export default function Footer(){
  return (
  <> 
    <FooterAchiment />
    <FooterMenu />
  </>
  );
}