import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'react-bootstrap';
import '../Styles/Main.css';
import { AiOutlineMergeCells, AiOutlineFieldTime, AiOutlineMessage, AiOutlineProject } from "react-icons/ai";


function HomeSlider() {
  return (
    <>
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src="images/slider3.jpg" alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Welcome to Rabiya Construction</h5>
          <p>Good buildings come from good people, and all problems are solved by good design.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img className="d-block w-100" src="images/slider1.jpg" alt="First slide"
        />
        <Carousel.Caption>
          <h5>Highway Construction</h5>
          <p>Construction of roads involves the paving, rehabilitation, and/or reclamation of degraded pavements in order to achieve a state of good repair and increase road traffic safety.</p>
        </Carousel.Caption>
      </Carousel.Item>
      
      <Carousel.Item>
        <img className="d-block w-100" src="images/slider2.jpg" alt="Third slide" />
        <Carousel.Caption>
          <h5>We Are Pasionate</h5>
          <p>
            Passion kept one fully in the present, so that time became a series of mutually exclusive nows.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    
    <div className="top_service_quote">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6 builder">
            <AiOutlineProject />
            <h4>Professional Builde</h4>
            <p>Good buildings come from good people, and all problems are solved by good design. </p>
          </div>
          <div className="col-lg-3 col-sm-6 builder">
            <AiOutlineMergeCells />
            <h4>We Deliver Quality</h4>
            <p>Almost all quality improvement comes via simplification of design, layout, processes, and procedures. </p>
          </div>
          <div className="col-md-3 col-sm-6 builder">
            <AiOutlineFieldTime />
            <h4>Always On Time</h4>
            <p>Time and effort can get you anything you want in the world. But nothing in the world can get you more time. </p>
          </div>
          <div className="col-md-3 col-sm-6 builder">
            <AiOutlineMessage />
            <h4>We Are Pasionate</h4>
            <p>Passion kept one fully in the present, so that time became a series of mutually exclusive nows. </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

function HomeAbout() {
  return (
    <>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title">About <span>Rabiya Construction</span></h2><span className="sec-title__divider"></span>
              </div>
              <p>Our company was established in Sunsari Nepal in the year of 2006. We "Rabiya Construction" are engaged in providing various kind of civil construction services that include Rainwater , Harvesting , Electrical Works Horticulture Services, Plumbing Contractors, Civil Construction works, Roads, Highways & Industrial Roads, Designing, Fabrication and Laying Services. Exteriors services. These are offered as per requirement of our esteemed clients. </p>
              <p>We use high quality raw material throughout our service in every field as we are one of the renowned quality centric organization and quality is our prior aim.</p><br />
              <a className="btn btn-primary btn-outline" href="/about-us">More About Us</a>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="about">
              <img src="/images/about.jpg" alt=""/>
              <blockquote className="quote-02 about-quote">
                <p className="quote-02__text">Rabiya Construction is a dynamic and forward-thinking construction company committed to delivering high-quality and innovative projects.</p>
                <div className="authorbox">
                  <div className="authorbox__avartar">
                  <img src="/images/profile.jpg" alt="Md Seraj Ansari" />
                  </div>
                  <div className="authorbox__info">
                    <h5 className="authorbox__name">MD Seraj Ansari</h5>
                    <p className="authorbox__work">Founder</p>
                  </div>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

function HomeService() {
  return (
    <section className="service-section" id="id3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2 ">
            <div className="sec-title sec-title__lg-title md-text-center">
              <h2 className="sec-title__title">Our services</h2><span className="sec-title__divider"></span>
            </div>
            
          </div>
        </div>
        <div className="row row-eq-height">
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/design.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/design">Design</a></h2>
              <div className="services__desc">A design is a plan or specification for the construction of an object or system or for the implementation of an activity or process, or the result of that plan or specification in the form of a prototype, product or process.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/design">read more
              </a>
              
            </div>
            
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/civil.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/civil-construction">Civil Construction Works</a></h2>
              <div className="services__desc">Civil construction falls in the category of civil engineering which is all about designing, constructing and maintaining the physical and naturally built environment.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/civil-construction">read more
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/rainwater.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/rainwater-harvesting">Rainwater Harvesting</a></h2>
              <div className="services__desc">Rain water harvesting is a technique of collection and storage of rain water in surface (storage tanks) or sub surface aquifer before it is lost as surface runoff.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/rainwater-harvesting">read more
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/highway.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/highway-and-industry">Highway & Industrial Roads</a></h2>
              <div className="services__desc">The highways and roads market refers to the construction and maintenance of transportation infrastructure including highways, railroads, bridges and other road.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/highway-and-industry">read more
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/fabrication.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/fabrication">Fabrication</a></h2>
              <div className="services__desc">Metal fabrication is the creation of metal structures by cutting, bending and assembling processes. ... As with other manufacturing processes, both human labor and automation are commonly used. A fabricated product may be called a fabrication, and shops specializing in this type of work are called fab shops.</div>
              <a className="btn btn btn-primary btn-custom" href="/service/fabrication">read more
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 ">
            <div className="services">
              <div className="services__img"><img src="/images/road.jpg" alt="" /></div>
              <h2 className="services__title"><a href="/service/roads">Roads</a></h2>
              <div className="services__desc">A road is a thoroughfare, route, or way on land between two places that has been paved or otherwise improved to allow travel by foot or by some form of conveyance (including a motor vehicle, cart, bicycle, or horse).</div>
              <a className="btn btn btn-primary btn-custom" href="/service/roads">read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function HomeProject() {
  return (
    <section className="project_section">
      <Container>
        <div className="row">
          <div className="col-lg-12 col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2 ">
            <div className="sec-title sec-title__lg-title md-text-center">
              <h2 className="sec-title__title">Our Projects</h2><span className="sec-title__divider"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/lights.jpg">
                <img src="/images/simara.jpg" alt="Road" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Simara Road Construction</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/nature.jpg">
                <img src="/images/civil.jpg" alt="Civil" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Siraha Projects</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/fjords.jpg">
                <img src="/images/hydro.jpg" alt="Hydro" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Construction of Hydroelectric Project</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/lights.jpg">
                <img src="/images/bridge.jpg" alt="Bridge" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Construction of 80m bridge</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/nature.jpg">
                <img src="/images/road.jpg" alt="Road" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Construction of Road Siraha</h2>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="thumbnail">
              <a href="/w3images/fjords.jpg">
                <img src="/images/rainwater.jpg" alt="Sypply" style={{ width: '100%' }} />
                <div className="caption">
                  <h2 className="projects__title">Small towns water supply & sanitation sector project</h2>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}



export default function HomePage(){
  return (
  <> 
    <HomeSlider />
    <HomeAbout />
    <HomeService />
    <HomeProject />
  </>
  );
}