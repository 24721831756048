import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Main.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container } from 'react-bootstrap';

function AboutDetail() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active href="/about-us">About Us</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title">About <span>Rabiya Construction</span></h2><span className="sec-title__divider"></span>
              </div>
              <p>Our company was established in Sunsari Nepal in the year of 2006. We "Rabiya Construction" are engaged in providing various kind of civil construction services that include Rainwater , Harvesting , Electrical Works Horticulture Services, Plumbing Contractors, Civil Construction works, Roads, Highways & Industrial Roads, Designing, Fabrication and Laying Services. Exteriors services. These are offered as per requirement of our esteemed clients. We use high quality raw material throughout our service in every field as we are one of the renowned quality centric organization and quality is our prior aim. We procure that raw material form quality driven organization that is checked at our quality inspection unit as to provide unique quality services to our esteemed clients. We offer these services after completion of planing and designing of the project.</p><br />
             
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
export default function AboutUs(){
  return (
  <> 
    <AboutDetail />
  </>
  );
}