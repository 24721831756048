import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Main.css';
import { AiOutlineFacebook, AiFillTwitterSquare, AiOutlineWhatsApp, AiOutlineInstagram   } from "react-icons/ai";
import {Container, Form, Button} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function ContactDetail() {
  return (
    <>
    <section className="top_breadcrumb" style={{ backgroundImage: "url(/images/breadcrumb.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active href="/about-us">Contact Us</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="md-section" id="id2" style={{backgroundColor : '#fff', padding : '60px 0'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-md-12">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title">Find<span>Us</span></h2><span className="sec-title__divider"></span>
              </div>
              <ul className="contact">
                <li><span>Phone:</span> <a href="tel:9862180882">+977 9862180882</a></li>
                <li><span>Email:</span> <a href="mailto:mseraj782@gmail.com">mseraj782@gmail.com </a></li>
                <li><span>Address:</span> Sunsari, Nepal</li>
              </ul>
              <ul className="socail_icon"> 
                  <li>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                      <AiOutlineFacebook title="Facebook" />
                    </a>
                  </li> 
                  <li>
                    <a href="https://twitter.com" target="_blank" rel="noreferrer">
                      <AiFillTwitterSquare title="Twitter" />
                    </a>
                  </li> 
                  <li>
                    <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                      <AiOutlineInstagram title="Instagram" />
                    </a>
                  </li> 
                  <li>
                    <a href="https://whatsapp.com/" target="_blank" rel="noreferrer">
                      <AiOutlineWhatsApp title="WhatsApp" />
                    </a>
                  </li>
                </ul>
            </div>
            <div className="google_map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d91564.77880053554!2d87.16920392289637!3d26.601266647397015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ef6d75da049a03%3A0xfe3e66a15ceec1fb!2sSonapur!5e0!3m2!1sen!2snp!4v1704691111455!5m2!1sen!2snp" width="100%" height="280" style={{ border: "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="myFrame"></iframe>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-12">
            <div className="mb-30">
              <div className="sec-title sec-title__lg-title">
                <h2 className="sec-title__title">Make an Enquiry <span>Here</span></h2><span className="sec-title__divider"></span>
              </div>
              <Form method="POST" action="#">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control name="fullname" type="text" placeholder="Enter Your Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control name="email" type="email" placeholder="Enter Your Email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control name="phone" type="text" placeholder="Enter Your Phone Number" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" name="message" placeholder="Leave your Message Here" style={{ height: '100px' }}/>
                </Form.Group>
                <Button variant="btn btn-primary" type="submit" className="btn-outline">
                  Submit
                </Button>
            </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
export default function ContactUs(){
  return (
  <> 
    <ContactDetail />
  </>
  );
}